import React from "react";

const AddTokenButton = (props: {
    id: string,
    label?: string
    onAddToken: (token: string) => void
}) => {

    const generateToken = () => {
        return `[[${props.id}]]`;
    }

    return <div className={"d-flex flex-row align-items-center"}>
        <button className={"btn btn-primary"} data-testid={`add-token-${props.id}`} onClick={()=>props.onAddToken(generateToken())}>+</button>
        {props.label && <div className={"m-2"}>{props.label}</div>}
    </div>
};

export default AddTokenButton;