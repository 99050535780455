import React, {useEffect, useRef, useState} from "react";
import InputField from "../Components/Fields/InputField.component";
import AddTokenButton from "../Components/Fields/AddTokenButton.component";
import ValueTokenField from "../Components/Fields/ValueTokenField.component";
import {useNavigate, useParams} from "react-router-dom";
import {bilans} from "../Content/bilans";
import useTinyMCE from "../Hooks/useTinyMCE";
import {BilanDatas} from "../app/types";
import useTextInterpreter from "../Hooks/useTextInterpreter";
import { useBeforeunload } from 'react-beforeunload';
import exporterHelper from "../Helpers/Exporter.helper";
import useLocalStorage from "../Hooks/useLocalStorage";
import {Bilan} from "../Types/Bilan";
import {LOCALSTORAGE_KEY} from "../app/constants";

const GenerateurScreen = () => {

    const {id: bilanId} = useParams();
    const [bilan,setBilan] = useState<Bilan>({
        titre: "",
        contenu: "",
        id: ""
    });
    const {init,doInsert,getContent} = useTinyMCE();
    const {interpretText} = useTextInterpreter();
    const {get,set} = useLocalStorage();
    const navigate = useNavigate();
    const bilanDatas: BilanDatas = {
        nomDocument : "",
        nom : "",
        patient : "",
        ilelle : "",
        percentile : "",
        ecartType : ""
    };
    useBeforeunload((event) => {
        event.preventDefault();
    });

    useEffect(()=>{
        const bilanData: Bilan = get(LOCALSTORAGE_KEY);
        if(bilanData) setBilan(bilanData);
        init(onBlur);
    },[bilanId]);

    const onBlur = (content: string) => {
        set(LOCALSTORAGE_KEY, {...bilan,contenu: content});
    }

    const hasBeenReloaded = () => {
        var perfEntries = performance.getEntriesByType("navigation");
        console.log(perfEntries)
        return (
            (window.performance.navigation && window.performance.navigation.type === 1) ||
            window.performance
                .getEntriesByType('navigation')
                .map((nav: any) => nav.type)
                .includes('reload')
        );
    }


    const onInputChange = (id: string, value: string) => {
        bilanDatas[id as keyof BilanDatas] = value;
    }

    const onAddToken = (token: string) => {
        doInsert(token)
    }

    const onExport = () => {
        const content = getContent();
        const interpreted = interpretText(content, bilanDatas);
        exporterHelper.export2Word(interpreted,bilanDatas.nomDocument || "bilan");
    }



    return <div data-testid={"generateur-wrapper"}>

        <div className="container-fluid m-3">
            <div className="row">
                <div className="col col-9">
                    <h1 className="display-6 d-flex flex-row">
                        <button onClick={()=>navigate("/")} className={"btn btn-small btn-outline-primary me-3"}>&lt;</button>
                        {bilan.titre}
                    </h1>
                    <textarea data-testid="generateur-editeur" defaultValue={bilan.contenu || ''}></textarea>
                </div>
                <div className="col col-3">
                    <div className="card">
                        
                        <div className="card-body">

                            <div className={"d-flex flex-row mt-2"}>
                                <InputField id={"nomDocument"} placeholder={"Ex : bilan_leo_2014"} label={"Document"} initialValue={""} onChange={onInputChange} />
                            </div>
                            <div className={"d-flex flex-row mt-2"}>
                                <AddTokenButton id={"nom"} onAddToken={onAddToken} />
                                <InputField id={"nom"} label={"Nom"} placeholder={"Ex : John doe"} initialValue={""} onChange={onInputChange} />
                            </div>
                            <div className={"d-flex flex-row mt-2"}>
                                <AddTokenButton id={"patient"} onAddToken={onAddToken} />
                                <InputField id={"patient"} placeholder={"Ex : le patient"} label={"Patient.e"} initialValue={""} onChange={onInputChange} />
                            </div>
                            <div className={"d-flex flex-row mt-2"}>
                                <AddTokenButton id={"ilelle"} onAddToken={onAddToken} />
                                <InputField id={"ilelle"} placeholder={"Ex : il"} label={"Il/Elle"} initialValue={""} onChange={onInputChange} />
                            </div>
                            <div className={"mt-2"}>
                                <ValueTokenField id={"percentile"} label={"Percentile"} onAddToken={onAddToken} initialValue={""} placeholder={"Ex: 15"}/>
                            </div>
                            <div className={"mt-2"}>
                                <ValueTokenField id={"ecartType"} label={"ET"} onAddToken={onAddToken} initialValue={""} placeholder={"Ex: -0.9"}/>
                            </div>
                            <div className={"d-flex flex-row mt-2"}>
                                <AddTokenButton id={"moyenne"} label={"Moyenne"} onAddToken={onAddToken} />
                            </div>
                            <div className={"d-flex flex-row mt-2"}>
                                <AddTokenButton id={"zoneFragilite"} label={"Zone de fragilité"} onAddToken={onAddToken} />
                            </div>
                            <div className={"d-flex flex-row mt-2"}>
                                <AddTokenButton id={"zonePathologique"} label={"Zone pathologique"} onAddToken={onAddToken} />
                            </div>
                            <hr/>
                            <div className={"d-flex flex-row mt-2"}>
                                <button className={"btn btn-info"} data-testid={"bouton-export"} onClick={onExport}>Export</button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>




    </div>;
};

export default GenerateurScreen;