const exporterHelper = {
    export2Word : (content: string, filename = '') => {
        const preHtml = "<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title>Export HTML To Doc</title></head><body>";
        const postHtml = "</body></html>";
        const html = preHtml+content+postHtml;
        const source = 'data:application/vnd.ms-word;charset=utf-8,' + encodeURIComponent(html);
        const fileDownload = document.createElement("a");
        document.body.appendChild(fileDownload);
        fileDownload.href = source;
        fileDownload.download = filename+'.doc';
        fileDownload.click();
        document.body.removeChild(fileDownload);
    }
}

export default exporterHelper;