import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import "./assets/bootstrap.min.css";
import {
    BrowserRouter as Router,
    Routes,
    Route
} from "react-router-dom";
import BilansScreen from "./Screens/Bilans.screen";
import {bilans} from "./Content/bilans";
import GenerateurScreen from "./Screens/Generateur.screen";

function App() {
  return (
    <div className="App">
        <Router>
            <Routes>
                <Route path={"/"} element={<BilansScreen bilans={bilans}/>} />
                <Route path={"/editor"} element={<GenerateurScreen />} />
            </Routes>
        </Router>
    </div>
  );
}

export default App;
