import useLocalStorage from "./useLocalStorage";

declare const tinymce: any;


const useTinyMCE = () => {

    const init = (onBlur: (content: string)=>void): void => {
        if (typeof tinymce !== "undefined"){
            tinymce.init({
                selector: 'textarea',
                plugins: 'advcode casechange export linkchecker autolink lists checklist powerpaste table advtable tinymcespellchecker',
                toolbar: 'undo redo | styleselect bold italic alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | forecolor backcolor | removeformat | table | casechange ',
                toolbar_mode: 'floating',
                forced_root_blocks : true,
                height: 600,
                setup: (editor: any) => {
                    editor.on('blur', () => onBlur(getContent()));
                }
            });
        }
    }

    const doInsert = (token: string): void => {
        tinymce.activeEditor.insertContent(token);
    }

    const getContent = (): string => {
        return tinymce.activeEditor.getContent();
    }

    return {init,doInsert,getContent};
};

export default useTinyMCE;