import {BilanDatas} from "../app/types";

const useTextInterpreter = () => {

    const interpretText = (content: string, datas: BilanDatas): string => {

        let html = content;
        for (const [k, v] of Object.entries(datas)) {

            if (k === "ilelle") {
                html = html.replaceAll('[[ilelle+]]', capitalizeFirstLetter(v));
                html = html.replaceAll('[[ilelle]]', v);
            } else if (k === "patient") {
                html = html.replaceAll('[[patient+]]', capitalizeFirstLetter(v));
                html = html.replaceAll('[[patient]]', v);
            } else if (k !== '') {
                html = html.replaceAll(`[[${k}]]`, v);
            }
        }

        html = html.replaceAll('[[moyenne]]', '<span style="color:green">la moyenne</span>');
        html = html.replaceAll('[[zoneFragilite]]', '<span style="color:orange">la zone de fragilité</span>');
        html = html.replaceAll('[[zonePathologique]]', '<span style="color:red">la zone pathologique</span>');

        const ecartTypeTokens = html.match(/\[\[ecartType (-|\+)[^\]]+\]\]/gi);
        if (ecartTypeTokens !== null && ecartTypeTokens.length) {
            for (let i = 0; i < ecartTypeTokens.length; i++) {
                const et = ecartTypeTokens[i];
                const value = getEcartType(et);

                if (value !== null) {
                    html = html.replace(et, value);
                }

            }
        }


        const percentileTokens = html.match(/\[\[percentile [^\]]+\]\]/gi);
        if( percentileTokens !== null && percentileTokens.length ){
            for( let i =0; i < percentileTokens.length; i++ ){
                const p = percentileTokens[i];
                const value = getPercentile(p);

                if( value !== null ){
                    html = html.replace(p,value);
                }

            }
        }

        return html;
    }

    const capitalizeFirstLetter = (str: string) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    const getEcartType = (value: string) => {

        const et = value.match(/(-|\+)\d+\.?\d*/);

        if (et && et.length) {

            const ecarType = parseFloat(et[0]);

            let css = '';

            if (ecarType >= -0.9) {
                css = 'green';
            } else if (-1 >= ecarType && ecarType >= -1.9) {
                css = 'orange';
            } else if (ecarType <= -2) {
                css = 'red';
            }

            return '<span style="color:' + css + '">' + ecarType + ' e.t.</span>';
        } else {
            return null;
        }

    }


    const getPercentile = (value: string) => {

        const p = value.match(/\d+\.?\d*/);

        if( p && p.length ){

            const percentile = parseInt(p[0]);

            let css = '';

            if( percentile >= 0 &&  percentile <= 10 ){
                css = 'red';
            }else if( percentile >= 11 &&  percentile <= 25  ){
                css = 'orange';
            }else if( percentile > 25 ){
                css = 'green';
            }

            return '<span style="color:'+css+'">percentile '+percentile+'</span>';
        }else{
            return null;
        }

    }

    return {interpretText}

};

export default useTextInterpreter;