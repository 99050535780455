import React from "react";
import {Bilan} from "../Types/Bilan";
import BilanCard from "../Components/Bilans/BilanCard.component";

const BilansScreen = (props: {
    bilans: Bilan[]
}) => {

    return <div className={"container mt-5"}>
        <div className="jumbotron jumbotron-fluid">
            <div className="container">
                <h1 className="display-4">Choix du bilan</h1>
            </div>
        </div>

        <div className="d-flex flex-row-wrap align-items-center">
            {props.bilans.map((b,k)=><div key={b.id} className={"m-2 flex-grow-1"}>
                <BilanCard bilan={b}/>
            </div>)}
        </div>

    </div>;
};

export default BilansScreen;