import {Bilan} from "../Types/Bilan";

const useLocalStorage = () => {

    const get = (key: string) => {
        const data = window.localStorage.getItem(key);

        if(data) return JSON.parse(data);

        return null;
    };
    const set = (key: string, value: Bilan) => window.localStorage.setItem(key, JSON.stringify(value));

    return {get,set};

};

export default useLocalStorage;