import React from "react";
import {InputTokenProps} from "../../app/types";

const InputField = (props: InputTokenProps) => {

    return <div data-testid={`token-field-${props.id}`} className={"d-flex flex-grow-1"}>
        <label htmlFor={props.id} className={"m-2 form-label"}>{props.label}</label>
        <input className={"form-control form-control-sm"} placeholder={props.placeholder || ''} type="text" id={props.id} defaultValue={props.initialValue} onChange={(e)=>props.onChange(props.id,e.currentTarget.value)}/>
    </div>
};

export default InputField;