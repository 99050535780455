import React from "react";
import {Bilan} from "../../Types/Bilan";
import {useNavigate} from "react-router-dom";
import docIcon from "../../assets/document.svg";
import useLocalStorage from "../../Hooks/useLocalStorage";
import {LOCALSTORAGE_KEY} from "../../app/constants";

const BilanCard = (props:{
    bilan: Bilan
}) => {

    const {bilan} = props;
    const navigate = useNavigate();
    const {set} = useLocalStorage();

    const onclick = () => {
        set(LOCALSTORAGE_KEY,bilan);
        navigate(`/editor`);
    }

    return <div className="card text-center" data-testid={"bilan-card"}>

        <div className={"row  mt-3"}>
            <div className="col"></div>
            <div className={"col align-self-center"}>
                <img src={docIcon} alt="" className="card-img-top" style={{width: 50}}/>
            </div>
            <div className="col"></div>
        </div>
        <div className="card-body">
            <h5 className="card-title">{bilan.titre}</h5>
            <button onClick={onclick} className="btn btn-primary">Charger</button>
        </div>
    </div>


};

export default BilanCard;