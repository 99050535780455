import React, {useState} from "react";
import AddTokenButton from "./AddTokenButton.component";
import InputField from "./InputField.component";


const ValueTokenField = (props: {
    id: string,
    label: string,
    initialValue: string,
    placeholder?: string,
    onAddToken: (token: string) => void
}) => {

    const [value,setValue] = useState<string>("");
    const [showError,setShowError] = useState<boolean>(false);

    const onAddToken = () => {
        setShowError(value === "");
        if(value){
            props.onAddToken(`[[${props.id} ${value}]]`);
        }
    };

    return <div>
        {showError && <div className="alert alert-danger alert">Veuillez saisir une valeur</div>}
        <div className={"d-flex flex-row align-items-center"} data-testid={`value-token-${props.id}`}>
            <AddTokenButton id={props.id} onAddToken={onAddToken} />
            <InputField id={props.id} label={props.label} placeholder={props.placeholder} initialValue={props.initialValue} onChange={(id, value)=>setValue(value)} />
        </div>
    </div>;
};

export default ValueTokenField;